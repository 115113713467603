import React from 'react';
import './ColorLegend.css';

const ColorLegend = ({ items }) => (
    <div className="legend">
        {items.map((item, index) => (
            <div key={index} className="legend-item">
                <div
                    className="legend-color"
                    style={{ backgroundColor: item.color }}
                ></div>
                <div className="legend-label">{item.label}</div>
            </div>
        ))}
    </div>
);

export default ColorLegend;
