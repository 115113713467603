import React from "react";
import Map from "./components/Map/Map";
import logo from './assets/CarbinLogo.png';  // Make sure to update the path to your actual logo file

import './App.css';


const App = () => {
  return (
    <div className="app-container">
      <header className="app-header">
        <img src={logo} alt="Company Logo" className="logo" /> { }
      </header>
      <Map />
    </div>
  );
};

export default App;